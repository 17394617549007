#back-arrow{
    position: fixed;
    top: 0;
    left: 0;
    margin: 10px;
}

.cowculator-title{
    color: var(--dark-purple);
    font-size: 50px;
    font-weight: 400;
}

#cowculator-tagline{
    color: var(--dark-purple);
    font-size: 40px;
    font-style: italic;
    font-weight: 400;
}

#app-store{
    color: var(--dark-purple);
    font-weight: 400;
}

.previewImages{
    display: flex;
    flex-direction: row;
}

.previewImage{
    width: 20%;
    margin-right: 15px;
    border-radius: 10px;
}

.privacy-policy h2{
    font-weight: 600;
    font-size: 30px;
}

.privacy-policy h3{
    font-weight: 500;
}

@media screen and (max-width: 768px) {
    #cowculator-title{
        font-size: 45px;
    }

    #cowculator-tagline{
        font-size: 35px;
    }

    .previewImage{
        width: 30%;
    }
}