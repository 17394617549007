.section-solid{
  min-height: 100vh;
  background-color: var(--light-purple);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.section-gradient{
  min-height: 100vh;
  background: var(--gradient-background);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.rectangle{
  background: #ECE0EA;
  width: 60vw;
  min-height: 50vh;
  padding: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: left;
}

.container{
  margin-top: 10px;
  margin-bottom: 10px;
  width: 85%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
}

.button{
  background-color: var(--dark-teal);
  border-radius: 5px;
  border: none;
  outline: none;
  color: var(--light-purple);
  font-family: 'Crimson Pro', serif;
  font-size: 40px;
  text-align: center;
  width: fit-content;
  transition: 0.2s ease-in;
  margin: 5px 5px 5px 0px;
}

button:hover{
  cursor: pointer;
  transform: scale(1.05);
  transition: 0.2s ease-out;
}

.icon{
  font-size: 50px;
  margin: 2px;
  color: var(--dark-purple);
}

.back-arrow{
  position: fixed;
  top: 0;
  left: 0;
  margin: 10px;
}

.back-arrow:hover{
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .rectangle{
    width: 75vw;
    padding: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .icon{
    font-size: 40px;
  }

  .button{
    font-size: 30px;
  }
}
