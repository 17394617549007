.contact-header{
    color: var(--dark-purple);
    font-size: 45px;
    font-weight: 400;
}

.contact-buttons .button{
    margin-right: 20px;
}

.contact-text{
    color: var(--dark-teal);
    font-size: 40px;
    font-weight: 400;
}

@media screen and (max-width: 768px) {
    .contact-header{
        font-size: 40px;
    }
    .contact-text{
        font-size: 25px;
    }
}