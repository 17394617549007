#about-text{
    width: 100%;
    color: var(--dark-purple);
    font-size: 45px;
    font-weight: 400;
    margin-bottom: 10px;
}

#about-subtext{
    width: 100%;
    color: var(--dark-purple);
    font-size: 35px;
    font-style: italic;
    font-weight: 400;
    margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
    #about-text{
        font-size: 35px;
    }

    #about-subtext{
        font-size: 25px;
    }
}