@import url('https://fonts.googleapis.com/css2?family=Crimson+Pro&display=swap');

:root{
	--dark-purple: #720C61;
	--light-purple: #ECE0EA;
	--dark-teal: #1C6C85;
  	--black: #1B1C1E;
	--gradient-background: radial-gradient(128.47% 70.71% at 50% 50%, #5F2323 0%, #720C61 27.78%, #E0D5D5 81.42%, #FFF 100%);
}

/* width */
::-webkit-scrollbar{
    width: 1em;
}

/* Track */
::-webkit-scrollbar-track{
    background: var(--light-purple);
}

/* Handle */
::-webkit-scrollbar-thumb{
    background: var(--dark-purple);
    border-radius: 20px;
    border: 3px solid var(--dark-purple);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover{
    background: #952E84;
    border: 3px solid #952E84;
	cursor: pointer;
}

body{
	margin: 0px;
  	font-family: 'Crimson Pro', serif;
	scroll-behavior: smooth;
}

p{
	margin: 0px;	
	font-size: 25px;
	color: var(--dark-purple);
}

h1{
	margin: 0px;
	font-weight: 400;
	color: var(--dark-purple);
}

h2{
	margin: 0px;
	font-weight: 400;
	color: var(--dark-purple);
}

h3{
	margin: 0px;
	font-weight: 400;
}

ul{
	margin: 0px;
	list-style-type: none;
}

a{
	transition: 0.2s ease-in;
	text-decoration: none;
	color: var(--dark-teal)
}

a:hover{
	transition: 0.2s ease-out;
	color: var(--dark-purple);
}