#welcome{
    color: var(--dark-purple);
    font-size: 50px;
}

#profilePic{
    width: 300px;
    margin: 30px;
}

#arrow{
    width: 90px;
    transition: 0.3s ease-in;
}

#arrow:hover{
    cursor: pointer;
    transform: scale(1.1);
    transition: 0.3s ease-out;
}