.h2{
    color: var(--dark-teal);
    font-size: 45px;
}

#arrow{
    width: 90px;
    transition: 0.3s ease-in;
}

#arrow:hover{
    cursor: pointer;
    transform: scale(1.1);
    transition: 0.3s ease-out;
}

.skillsContainer{
    display: flex;
    flex-wrap: wrap;
}

.skillItem{
    width: fit-content;
    padding: 5px 10px 5px 10px;
    margin: 5px;
    color: var(--dark-purple);
    font-size: 30px;
    border: 2px solid var(--dark-purple);
    border-radius: 20px;
}

.projectsContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.projectItem{
    padding: 5px 10px 5px 10px;
    margin: 5px 0px 5px 0px;
    color: var(--dark-purple);
    border: 2px solid var(--dark-purple);
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: 0.2s ease-in;
}

.projectItem:hover{
    transform: scale(1.01);
    transition: 0.2s ease-in;
}

.projectItem h3{
    font-size: 35px;
}

.projectItem p{
    font-size: 30px;
}

@media screen and (max-width: 768px) {
    .h2{
        font-size: 40px;
    }

    .skillItem{
        font-size: 25px;
    }

    .projectItem{
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .projectItem h3{
        font-size: 30px;
        text-align: center;
    }

    .projectItem p{
        font-size: 20px;
        text-align: center;
    }    
}